<dx-popup [visible]="true" height="600px" width="70%" [hideOnOutsideClick]="true" (onHiding)="closeCarousel()" [wrapperAttr]="{ class: 'image-carousel-popup' }">
	<dx-gallery
		[dataSource]="images"
		height="100%"
		width="100%"
		class="image-section"
		[loop]="false"
		[showIndicator]="images.length > 1 && showIndicator"
		[showNavButtons]="showNavButtons"
		[slideshowDelay]="slideshowDelay"
		(onItemClick)="openLightbox($event.itemData)"
		[elementAttr]="{ class: 'image-carousel-popup' }"
		(onSelectionChanged)="onSelectionChanged($event)"
		[selectedIndex]="currentIndex"
		><div *dxTemplate="let galleryItem of 'item'">
			<div *ngIf="galleryItem.imageData == ''" class="loader-animation-sec">
				<div class="loader"></div>
			</div>
			<img class="zoomed-image" src="{{ galleryItem.imageData }}" />
		</div>
	</dx-gallery>
</dx-popup>
